var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"presale-bonus"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('login_form_text'))}})]),_c('div',{staticClass:"login-details-form"},[_c('generic-input',{attrs:{"type":"email","name":"email","placeholder":_vm.$t('email_field'),"validations":[
                   {
                     condition: _vm.$v.user.email.$error && !_vm.$v.user.email.required,
                     text: _vm.$t('required')
                   },
                   {
                     condition: _vm.$v.user.email.$error && !_vm.$v.user.email.email,
                     text: _vm.$t('email_must_be_valid')
                   }
                 ]},on:{"blur":function($event){return _vm.$v.user.email.$touch()}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('generic-input',{attrs:{"type":"password","name":"password","placeholder":_vm.$t('password_field'),"validations":[
                   {
                     condition: _vm.$v.user.password.$error && !_vm.$v.user.password.required,
                     text: _vm.$t('required')
                   },
                   {
                     condition: _vm.$v.user.password.$error && !_vm.$v.user.password.minLength,
                     text: _vm.$t('min_characters', { number: 8 })
                   }
                 ]},on:{"blur":function($event){return _vm.$v.user.password.$touch()}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('generic-input',{attrs:{"type":"password","name":"repeatPassword","placeholder":_vm.$t('confirm_password_field'),"validations":[
                   {
                     condition: _vm.$v.user.repeatPassword.$error && !_vm.$v.user.repeatPassword.required,
                     text: _vm.$t('required')
                   },
                   {
                     condition: _vm.$v.user.repeatPassword.$error && !_vm.$v.user.repeatPassword.sameAsPassword,
                     text: _vm.$t('password_and_confirmation_must_match')
                   },
                 ]},on:{"blur":function($event){return _vm.$v.user.repeatPassword.$touch()}},model:{value:(_vm.user.repeatPassword),callback:function ($$v) {_vm.$set(_vm.user, "repeatPassword", $$v)},expression:"user.repeatPassword"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }