<template>
  <section>
    <div class="presale-bonus">
      <p v-html="$t('login_form_text')" />
    </div>
    <div class="login-details-form">
      <generic-input v-model="user.email"
                     @blur="$v.user.email.$touch()"
                     type="email"
                     name="email"
                     :placeholder="$t('email_field')"
                     :validations="[
                     {
                       condition: $v.user.email.$error && !$v.user.email.required,
                       text: $t('required')
                     },
                     {
                       condition: $v.user.email.$error && !$v.user.email.email,
                       text: $t('email_must_be_valid')
                     }
                   ]"
      />
      <generic-input v-model="user.password"
                     @blur="$v.user.password.$touch()"
                     type="password"
                     name="password"
                     :placeholder="$t('password_field')"
                     :validations="[
                     {
                       condition: $v.user.password.$error && !$v.user.password.required,
                       text: $t('required')
                     },
                     {
                       condition: $v.user.password.$error && !$v.user.password.minLength,
                       text: $t('min_characters', { number: 8 })
                     }
                   ]"
      />
      <generic-input v-model="user.repeatPassword"
                     @blur="$v.user.repeatPassword.$touch()"
                     type="password"
                     name="repeatPassword"
                     :placeholder="$t('confirm_password_field')"
                     :validations="[
                     {
                       condition: $v.user.repeatPassword.$error && !$v.user.repeatPassword.required,
                       text: $t('required')
                     },
                     {
                       condition: $v.user.repeatPassword.$error && !$v.user.repeatPassword.sameAsPassword,
                       text: $t('password_and_confirmation_must_match')
                     },
                   ]"
      />
    </div>
  </section>
</template>
<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import GenericInput from '@/components/Core/Form/GenericInput'

export default {
  name: 'LoginDetailsForm',
  props: {
    payload: {
      type: Object,
      required: false
    }
  },
  components: {
    GenericInput
  },
  data: () => ({
    user: {
      email: '',
      password: '',
      repeatPassword: ''
    }
  }),
  validations: {
    user: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  mounted () {
    if (this.payload && Object.keys(this.payload).length !== 0) {
      this.user = this.payload
    }
  },
  methods: {
    resetUserForm () {
      this.user = {
        email: '',
        password: '',
        repeatPassword: ''
      }
    }
  },
  watch: {
    user: {
      deep: true,
      handler: function () {
        this.$v.$touch()
        this.$emit('validation-changed', !this.$v.$invalid)
      }
    }
  }
}
</script>
<style scoped>
.presale-bonus {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #009357;
  margin-bottom: 15px;
}
</style>
